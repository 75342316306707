import SwalOrig from 'sweetalert2';

export default SwalOrig.mixin({
  title: '',
  icon: 'warning',
  buttonsStyling: false,
  customClass: {
    confirmButton: 'btn btn-primary mx-1',
    cancelButton: 'btn btn-white mx-1',
    denyButton: 'btn btn-white mx-1',
  },
  showCloseButton: false,
  allowOutsideClick: false,
  allowEscapeKey: false,
  allowEnterKey: false,
  reverseButtons: true,
});
