import { registerLocaleData } from '@angular/common';

const angularLocales = new Map<string, string>([
  ['de-AT', 'de-AT'],
  ['de-DE', 'de'],
  ['en-BZ', 'en-BZ'],
  ['en-GB', 'en-GB'],
  ['en-PH', 'en-PH'],
  ['en-US', 'en'],
  ['es-AR', 'es-AR'],
  ['es-ES', 'es'],
  ['es-MX', 'es-MX'],
  ['fr-BE', 'fr-BE'],
  ['fr-FR', 'fr'],
  ['it-CH', 'it-CH'],
  ['it-IT', 'it'],
  ['nl-BE', 'nl-BE'],
  ['pt-BR', 'pt'],
  ['pt-PT', 'pt-PT'],
  ['zh-CHS', 'zh-Hans'],
]);

export async function loadLocale() {
  const locale = await import(
    // ! NON RIMUOVERE IL COMMENTO QUA SOTTO. SERVE PER GENERARE SOLO I LOCALE NECESSARI
    /* webpackInclude:/(de-AT|de|en-BZ|en-GB|en-PH|en|es-AR|es|es-MX|fr-BE|fr|it-CH|it|nl-BE|pt|pt-PT|zh-Hans).mjs$/ */
    `/node_modules/@angular/common/locales/${angularLocales.get(window.outputCulture) || 'en'}`
  );
  const extra = await import(
    // ! NON RIMUOVERE IL COMMENTO QUA SOTTO. SERVE PER GENERARE SOLO I LOCALE NECESSARI
    /* webpackInclude:/(de-AT|de|en-BZ|en-GB|en-PH|en|es-AR|es|es-MX|fr-BE|fr|it-CH|it|nl-BE|pt|pt-PT|zh-Hans).mjs$/ */
    `/node_modules/@angular/common/locales/extra/${angularLocales.get(window.outputCulture) || 'en'}`
  );

  registerLocaleData(locale.default, window.outputCulture, extra.default);

  return Promise.resolve();
}

