import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import minMax from 'dayjs/plugin/minMax';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekday from 'dayjs/plugin/weekday';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import duration from 'dayjs/plugin/duration';

const dayjsLocales = new Map<string, string>([
  ['de-AT', 'de-at'],
  ['de-DE', 'de'],
  ['en-BZ', 'en'],
  ['en-GB', 'en-gb'],
  ['en-PH', 'en'],
  ['en-US', 'en'],
  ['es-AR', 'es'],
  ['es-ES', 'es'],
  ['es-MX', 'es-mx'],
  ['fr-BE', 'fr'],
  ['fr-FR', 'fr'],
  ['it-CH', 'it-ch'],
  ['it-IT', 'it'],
  ['nl-BE', 'nl-be'],
  ['pt-BR', 'pt-br'],
  ['pt-PT', 'pt'],
  ['zh-CHS', 'zh-cn'],
]);

export async function setupDayjs() {
  return import(
    // ! NON RIMUOVERE IL COMMENTO QUA SOTTO. SERVE PER GENERARE SOLO I LOCALE NECESSARI
    /* webpackInclude:/(de-at|de|en|en-gb|es|es-mx|fr|it-ch|it|nl-be|pt-br|pt|zh-cn).js$/ */
    `dayjs/locale/${dayjsLocales.get(window.outputCulture) || 'en'}`
  ).then(() => {
    dayjs.locale(dayjsLocales.get(window.outputCulture));
    dayjs.extend(localizedFormat);
    dayjs.extend(isSameOrAfter);
    dayjs.extend(isSameOrBefore);
    dayjs.extend(weekOfYear);
    dayjs.extend(weekday);
    dayjs.extend(isBetween);
    dayjs.extend(customParseFormat);
    dayjs.extend(minMax);
    dayjs.extend(localeData);
    dayjs.extend(duration);
    // dayjs.Ls['en'].weekStart = 1;

    return Promise.resolve();
  });
}

